import axios, { AxiosError } from 'axios'
import { request, requestV3 } from './customAxios'
import config from './config'
import { getErrorMessageErrorResponse } from './utils'
import { ErrorResponse } from '../types/api-error'

interface ApiRequestParams {
  url: string
  method: string
  data: unknown
  isAuthenticated: boolean
  fallbackError: string
  onError?: (error: unknown) => void
  continueOnError?: boolean
  isAbsoluteUrl?: boolean
  sendCredentials?: boolean
  isV3Api?: boolean
  headers?: any
}

export const makeApiRequest = async (params: ApiRequestParams) => {
  const requestFn = params.isAuthenticated ? (params.isV3Api ? requestV3 : request) : axios.request
  try {
    const response = await requestFn({
      method: params.method,
      url:
        ((params.isAbsoluteUrl || params.isAuthenticated) && !params.isV3Api)
          ? params.url
          : params.isV3Api ? config.getBackendUrlV3(params.url) : config.getBackendUrl(params.url),
      data: params.data,
      withCredentials: true,
      // withCredentials:
      //   params.sendCredentials || (params.isAuthenticated ? false : true),
      headers: params.headers
    })

    if (response.data.respCode != 1 && params.fallbackError !== 'noFallback') {
      throw new Error(params.fallbackError)
    }

    return response.data
  } catch (e: unknown) {
    if (params.onError) {
      params.onError(e)
      if (params.continueOnError) {
        return
      }
    }
    if (e instanceof AxiosError) {
      throw new Error(
        getErrorMessageErrorResponse(
          e.response?.data as ErrorResponse,
          params.fallbackError
        )
      )
    }
    throw new Error(params.fallbackError)
  }
}
