import { useToken } from '../store/token-store'
import { useUser } from '../store/user-store'
import { clientV3 } from './customAxios'

export const fetchNewToken = async () => {
  try {
    const response = await clientV3.request({
      method: 'POST',
      url: '/Auth/grantAccessToken',
      data: {
        refresh_token: useUser.getState().refreshToken,
      },
      
    })
    useToken.setState({ accessToken: response.data.response.token })
    useUser.setState({ refreshToken: response.data.response.refresh_token })
    return response.data.response.token
  } catch (error) {
    useToken.setState({ accessToken: null })
    useUser.setState({ refreshToken: null, user: null })
    return null
  }
}

export const refreshAuth = async (failedRequest: any) => {
  const newToken = await fetchNewToken()

  if (newToken) {
    failedRequest.response.config.headers.Authorization = 'Bearer ' + newToken
    return Promise.resolve(newToken)
  } else {
    return Promise.reject()
  }
}
