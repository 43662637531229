export const ErrorMessages = {
  'error/incorrect-password': 'The password you entered is incorrect.',
  'error/user-not-found': 'The email you entered is not registered with us.',
  'error/token-invalid': 'Invalid token',
  'error/server-error': 'Something went wrong. Please try again later.',
  'error/invalid-request': 'The request is invalid.',
  'error/email-exists': 'The email you entered is already registered with us.',
  'error/phoneNumber-exist':
    'The phone number you entered is already registered with us.',
  'error/incorrect-otp': 'The verification code you entered is incorrect.',
  'error/already-registered-different-auth': `We couldn't recognize this email; please check if you signed up using social login with it and use that to sign in.`,
  'error/already-registered-different-method': `We couldn't recognize this email; please check if you signed up using email login with it and use that to sign in without social login.`,
  'error/phoneNumber-exists':
    'The phone number you entered is already registered with us.',
  'error/already-Verified': 'The phone number you entered is already verified.',
  'error/insufficient-balance': 'Insufficient balance',
  'error/invalid-amount': 'The amount you entered is invalid',
  'error/invalid-acc-num': 'The account number you entered is invalid',
  'error/invalid-ifsc': 'The IFSC code you entered is invalid',
  'error/invalid-pan': 'The PAN number you entered is invalid',
  'error/bank-acc-dupl':
    'The bank account you entered is already registered with us',
  'error/pan-dupl': 'The PAN number you entered is already registered with us',
  'error/pay-verified': 'You have already verified your payment details',
  'error/pay-max-attempt': 'You have reached the maximum number of attempts',
  'error/pan-name-mismatch':
    'The name you entered does not match the name on your PAN card',
  'error/pan-verify-fail': 'PAN verification failed',
  'error/bank-verify-fail': 'Bank verification failed',
  'error/channelName-already-exists': 'Channel name already exists',
  'error/This-channel-name-already-taken': 'This channel name is already taken',
  'error/otp-send-block': 'OTP limit exceed',
  'error/account-blocked': 'Account locked due to 3 unsuccessful login attempts. Retry after 6 hours.',
  'error/same-password': 'The new password cannot be the same as the old password.',
}

type ApiError = keyof typeof ErrorMessages

export interface ErrorResponse {
  respCode: string
  message: ApiError
}
